import config from '@/utils/config'
import moment from 'moment/moment'
import request from '@/utils/request'

// 实况站点监测 —— 监测站点数据迟到、缺报
const liveMonitorData = () => {
  const params = {
    adminCodes: config.defaultStationObj.adminCode,
    elements: 'station_name,station_id_c,datetime,iymdhm',
  }
  return request.get('/cw/surface_data/list/surf_hour_data', params)
  .then((data) => {
    if (data.length > 0) {
      return data
    }
    return []
  }).catch((err) => {
    console.log(err)
    return []
  })
}
// 24h要素统计
const stat24hData  = () => {
  const timeStart = moment(new Date(), 'YYYYMMDDHHmmss').subtract(23, 'hours').format('YYYYMMDDHH0000')
  const timeEnd = moment(new Date(), 'YYYYMMDDHHmmss').format('YYYYMMDDHH0000')
  const params = {
    adminCodes: config.defaultStationObj.adminCode,
    timeRange: `${timeStart},${timeEnd}`,
    elements: 'station_name,station_id_c,lat,lon,pre_1h,pre_3h,tem_max,tem_min,win_s_max,win_s_inst_max',
  }
  return request.get('/cw/surface_stat2/list/surf_hour_data', params)
  .then((data) => {
    if (data.length > 0) {
      return data
    }
    return []
  }).catch((err) => {
    console.log(err)
    return []
  })
}
// 站点数据 —— station_info
const stationInfoList = () => {
  const params = {
    adminCodes: config.defaultStationObj.adminCode,
  }
  return request.get('/cw/station_info/list', params)
  .then((data) => {
    if (data.length > 0) {
      return data
    }
    return []
  }).catch((err) => {
    console.log(err)
    return []
  })
}
// 分钟实况
const minuteWeatherData = (staIds: any) => {
  let params = {}
  if (staIds) {
    params = {
      staIds,
      elements: 'station_name,station_id_c,cnty,lat,lon,pre_1h,tem,rhu,vis,prs,win_s_avg_2mi,win_d_avg_2mi,win_s_inst_max,win_d_inst_max,vis,datetime',
    }
  } else {
    params = {
      adminCodes: config.defaultStationObj.adminCode,
      elements: 'station_name,station_id_c,cnty,lat,lon,pre,datetime',
    }
  }
  return request.get('/cw/surface_data/list/surf_min_data', params)
  .then((data) => {
    if (data.length > 0) {
      return data
    }
    return []
  }).catch((err) => {
    console.log(err)
    return []
  })
}
// 小时实况
const liveWeatherData = (staIds: any) => {
  let params = {}
  if (staIds) {
    params = {
      staIds,
      elements: 'station_name,station_id_c,cnty,lat,lon,pre_1h,tem,rhu,vis,prs,win_s_avg_2mi,win_d_avg_2mi,win_s_inst_max,win_d_inst_max,vis,datetime',
    }
  } else {
    params = {
      adminCodes: config.defaultStationObj.adminCode,
      elements: 'station_name,station_id_c,cnty,lat,lon,pre_1h,pre_3h,pre_6h,pre_12h,pre_24h,tem,tem_min,tem_max,win_s_avg_2mi,win_d_avg_2mi,win_s_max,win_d_s_max,win_s_inst_max,win_d_inst_max,datetime',
      refreshPrePeriod: 24,
    }
  }
  return request.get('/cw/surface_data/list/surf_hour_data', params)
  .then((data) => {
    if (data.length > 0) {
      return data
    }
    return []
  }).catch((err) => {
    console.log(err)
    return []
  })
}
// 小时预报
const hourWeatherData  = (lon: any, lat: any) => {
  const params = {
    lon,
    lat,
    elements: 't2m,wp,rain',
    limit: 48,
  }
  return request.get('/gpr/point', params)
  .then((data) => {
    if (data.length > 0) {
      return data
    }
    return []
  }).catch((err) => {
    console.log(err)
    return []
  })
}
// 天预报
const dayWeatherData  = (lon: any, lat: any, limit: any) => {
  const params = {
    lon,
    lat,
    elements: 'tmax24,tmin24,rain24',
    limit,
  }
  return request.get('/gpr/point', params)
  .then((data) => {
    if (data.length > 0) {
      return data
    }
    return []
  }).catch((err) => {
    console.log(err)
    return []
  })
}
// 天预报--天气现象
const dayWeatherWp  = (lon: any, lat: any, limit: any) => {
  const params = {
    lon,
    lat,
    elements: 'wp12',
    limit,
  }
  return request.get('/gpr/point', params)
  .then((data) => {
    if (data.length > 0) {
      return data
    }
    return []
  }).catch((err) => {
    console.log(err)
    return []
  })
}
// 小时统计
const hourStatData  = (timeStart: any, timeEnd: any) => {
  const params = {
    adminCodes: config.defaultStationObj.adminCode,
    timeRange: `${timeStart},${timeEnd}`,
    elements: 'station_name,station_id_c,lat,lon,pre_1h,tem,tem_max,tem_min,win_s_avg_2mi,win_s_max,win_s_inst_max,',
  }
  return request.get('/cw/surface_stat2/list/surf_hour_data', params)
  .then((data) => {
    if (data.length > 0) {
      return data
    }
    return []
  }).catch((err) => {
    console.log(err)
    return []
  })
}
export default {
  stationInfoList,
  liveMonitorData,
  stat24hData,
  minuteWeatherData,
  liveWeatherData,
  hourWeatherData,
  dayWeatherData,
  dayWeatherWp,
  hourStatData,
}
